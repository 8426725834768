import React from "react"
import { Navigate } from "react-router-dom"

// Profile
const UserProfile = React.lazy(() =>
  import("../pages/Authentication/user-profile")
)
// Authentication related pages
const Login = React.lazy(() => import("../pages/Authentication/Login"))
const Logout = React.lazy(() => import("../pages/Authentication/Logout"))
const Register = React.lazy(() => import("../pages/Authentication/Register"))
const ForgetPwd = React.lazy(() =>
  import("../pages/Authentication/ForgetPassword")
)

// Dashboard
const Dashboard = React.lazy(() => import("../pages/Dashboard/index"))

//Tables
const BasicTables = React.lazy(() => import("../pages/Tables/BasicTables"))
const DatatableTables = React.lazy(() =>
  import("../pages/Tables/DatatableTables")
)
const ResponsiveTables = React.lazy(() =>
  import("../pages/Tables/ResponsiveTables")
)
const DragDropTables = React.lazy(() =>
  import("../pages/Tables/DragDropTables")
)

//Job

const CandidateList = React.lazy(() =>
  import("../pages/JobPages/CandidateList")
)
const CandidateOverview = React.lazy(() =>
  import("../pages/JobPages/CandidateOverview")
)

//Ui
const UiAlert = React.lazy(() => import("../pages/Ui/UiAlert"))
const UiButtons = React.lazy(() => import("../pages/Ui/UiButtons"))
const UiCards = React.lazy(() => import("../pages/Ui/UiCards"))
const UiCarousel = React.lazy(() => import("../pages/Ui/UiCarousel"))
const UiColors = React.lazy(() => import("../pages/Ui/UiColors"))
const UiDropdown = React.lazy(() => import("../pages/Ui/UiDropdown"))
const UiGeneral = React.lazy(() => import("../pages/Ui/UiGeneral"))
const UiGrid = React.lazy(() => import("../pages/Ui/UiGrid"))
const UiImages = React.lazy(() => import("../pages/Ui/UiImages"))
const UiLightbox = React.lazy(() => import("../pages/Ui/UiLightbox"))
const UiModal = React.lazy(() => import("../pages/Ui/UiModal"))
const UiProgressbar = React.lazy(() => import("../pages/Ui/UiProgressbar"))
const UiTabsAccordions = React.lazy(() =>
  import("../pages/Ui/UiTabsAccordions")
)
const UiTypography = React.lazy(() => import("../pages/Ui/UiTypography"))
const UiVideo = React.lazy(() => import("../pages/Ui/UiVideo"))
const UiSessionTimeout = React.lazy(() =>
  import("../pages/Ui/UiSessionTimeout")
)
const UiRating = React.lazy(() => import("../pages/Ui/UiRating"))
const UiRangeSlider = React.lazy(() => import("../pages/Ui/UiRangeSlider"))
const UiNotifications = React.lazy(() => import("../pages/Ui/ui-notifications"))
const UiOffCanvas = React.lazy(() => import("pages/Ui/UiOffCanvas"))
const UiUtilitie = React.lazy(() => import("../pages/Ui/UiUtilitie"))
const UiPlaceholders = React.lazy(() => import("../pages/Ui/UiPlaceholders"))
const UiToasts = React.lazy(() => import("../pages/Ui/UiToast"))

//Pages
const PagesStarter = React.lazy(() => import("../pages/Utility/pages-starter"))
const PagesMaintenance = React.lazy(() =>
  import("../pages/Utility/pages-maintenance")
)
const PagesComingsoon = React.lazy(() =>
  import("../pages/Utility/pages-comingsoon")
)
const PagesTimeline = React.lazy(() =>
  import("../pages/Utility/pages-timeline")
)
const PagesFaqs = React.lazy(() => import("../pages/Utility/pages-faqs"))
const PagesPricing = React.lazy(() => import("../pages/Utility/pages-pricing"))
const Pages404 = React.lazy(() => import("../pages/Utility/pages-404"))
const Pages500 = React.lazy(() => import("../pages/Utility/pages-500"))

//Contacts
const ContactsGrid = React.lazy(() => import("../pages/Contacts/contacts-grid"))
const ContactsList = React.lazy(() =>
  import("../pages/Contacts/ContactList/contacts-list")
)
const ContactsProfile = React.lazy(() =>
  import("../pages/Contacts/ContactsProfile/contacts-profile")
)
const WorkspaceList = React.lazy(() =>
  import("../pages/workspace/workspace-list")
)
const RoadmapList = React.lazy(() => import("../pages/Roadmap/Roadmap-list"))
const NotificationList = React.lazy(() =>
  import("../pages/Notification/notification-list")
)
const ViewUserPage = React.lazy(() =>
  import("pages/Contacts/ContactList/viewUser")
)

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },

  // //profile
  { path: "/profile", component: <UserProfile /> },

  //job

  { path: "/candidate-list", component: <CandidateList /> },
  { path: "/candidate-overview", component: <CandidateOverview /> },

  // Contacts
  { path: "/contacts-grid", component: <ContactsGrid /> },
  { path: "/contacts-list", component: <ContactsList /> },
  { path: "/view-user", component: <ViewUserPage /> },
  { path: "/contacts-profile", component: <ContactsProfile /> },

  //Workspace
  { path: "/workspace-list", component: <WorkspaceList /> },

  //Workspace
  { path: "/notification-list", component: <NotificationList /> },

  //Roadmap
  { path: "/roadmap-list", component: <RoadmapList /> },

  // Tables
  { path: "/tables-basic", component: <BasicTables /> },
  { path: "/tables-datatable", component: <DatatableTables /> },
  { path: "/tables-responsive", component: <ResponsiveTables /> },
  { path: "/tables-dragndrop", component: <DragDropTables /> },

  // Ui
  { path: "/ui-alerts", component: <UiAlert /> },
  { path: "/ui-buttons", component: <UiButtons /> },
  { path: "/ui-cards", component: <UiCards /> },
  { path: "/ui-carousel", component: <UiCarousel /> },
  { path: "/ui-colors", component: <UiColors /> },
  { path: "/ui-dropdowns", component: <UiDropdown /> },
  { path: "/ui-general", component: <UiGeneral /> },
  { path: "/ui-grid", component: <UiGrid /> },
  { path: "/ui-images", component: <UiImages /> },
  { path: "/ui-lightbox", component: <UiLightbox /> },
  { path: "/ui-modals", component: <UiModal /> },
  { path: "/ui-progressbars", component: <UiProgressbar /> },
  { path: "/ui-tabs-accordions", component: <UiTabsAccordions /> },
  { path: "/ui-typography", component: <UiTypography /> },
  { path: "/ui-video", component: <UiVideo /> },
  { path: "/ui-session-timeout", component: <UiSessionTimeout /> },
  { path: "/ui-rating", component: <UiRating /> },
  { path: "/ui-rangeslider", component: <UiRangeSlider /> },
  { path: "/ui-notifications", component: <UiNotifications /> },
  { path: "/ui-offcanvas", component: <UiOffCanvas /> },
  { path: "/ui-utilities", component: <UiUtilitie /> },
  { path: "/ui-placeholders", component: <UiPlaceholders /> },
  { path: "/ui-toasts", component: <UiToasts /> },

  //Utility
  { path: "/pages-starter", component: <PagesStarter /> },
  { path: "/pages-timeline", component: <PagesTimeline /> },
  { path: "/pages-faqs", component: <PagesFaqs /> },
  { path: "/pages-pricing", component: <PagesPricing /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },

  { path: "/pages-maintenance", component: <PagesMaintenance /> },
  { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },
]

export { authProtectedRoutes, publicRoutes }

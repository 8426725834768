import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_USERS,
  GET_USER_PROFILE,
  ADD_NEW_USER,
  DELETE_USER,
  UPDATE_USER,
  GET_PAYMENT_DETAILS,
  GET_USER_OWNED_WORKSPACE,
  UPDATE_FREE_TRIAL,
  GET_BILLING_HISTORY,
  GET_USER_OTHER_WORKSPACE,
} from "./actionTypes"

import {
  getUsersSuccess,
  getUsersFail,
  getUserProfileSuccess,
  getUserProfileFail,
  addUserFail,
  addUserSuccess,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
  getUserPaymentDetailsSuccess,
  getUserPaymentDetailsFail,
  getUserOwnedWorkspaceSuccess,
  getUserOwnedWorkspaceFail,
  updateUserFreeTrialSuccess,
  updateUserFreeTrialFail,
  getUserBillingHistorySuccess,
  getUserBillingHistoryFail,
  getUserOtherWorkspaceSuccess,
  getUserOtherWorkspaceFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getUsers,
  getUserProfile,
  getUserPaymentDetail,
  addNewUser,
  updateUser,
  deleteUser,
  getUserOwnedWorkspaceAPI,
  updateFreeTrialAPI,
  getUserBillingHistory,
  getUserOtherWorkspaceAPI,
} from "../../helpers/fakebackend_helper"

function* fetchUsers({ payload: userData }) {
  try {
    const response = yield call(getUsers, userData)

    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchUserProfile({ payload: userId }) {
  try {
    const response = yield call(getUserProfile, userId)
    yield put(getUserProfileSuccess(response))
  } catch (error) {
    yield put(getUserProfileFail(error))
  }
}

function* fetchUserPaymentDetails({ payload: userId }) {
  try {
    const response = yield call(getUserPaymentDetail, userId)
    yield put(getUserPaymentDetailsSuccess(response))
  } catch (error) {
    yield put(getUserPaymentDetailsFail(error))
  }
}

function* fetchUserOwnedWorkspace({ payload: data }) {
  try {
    const response = yield call(getUserOwnedWorkspaceAPI, data)
    yield put(getUserOwnedWorkspaceSuccess(response))
  } catch (error) {
    yield put(getUserOwnedWorkspaceFail(error))
  }
}

function* fetchUserOtherWorkspace({ payload: data }) {
  try {
    const response = yield call(getUserOtherWorkspaceAPI, data)
    yield put(getUserOtherWorkspaceSuccess(response))
  } catch (error) {
    yield put(getUserOtherWorkspaceFail(error))
  }
}

function* fetchUserBillingHistory({ payload: data }) {
  try {
    const response = yield call(getUserBillingHistory, data)
    yield put(getUserBillingHistorySuccess(response))
  } catch (error) {
    yield put(getUserBillingHistoryFail(error))
  }
}

function* updateFreeTrial({ payload: trialData }) {
  try {
    const response = yield call(updateFreeTrialAPI, trialData)
    yield put(updateUserFreeTrialSuccess(response))
  } catch (error) {
    yield put(updateUserFreeTrialFail(error))
  }
}

function* onUpdateUser({ payload: user }) {
  const newUser = {
    display_name: user.display_name,
    email: user.email,
    full_name: user.full_name,
    id: user.id,
    is_verified: false,
    phone_number: null,
    profile_pic: null,
    status: user.status == 0 ? 1 : 0,
  }
  try {
    const response = yield call(updateUser, user.id)
    yield put(updateUserSuccess(newUser))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onDeleteUser({ payload: user }) {
  try {
    const response = yield call(deleteUser, user)
    yield put(deleteUserSuccess(response.data))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onAddNewUser({ payload: user }) {
  try {
    const response = yield call(addNewUser, user)

    yield put(addUserSuccess(response))
  } catch (error) {
    yield put(addUserFail(error))
  }
}

function* contactsSaga() {
  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(GET_USER_PROFILE, fetchUserProfile)
  yield takeEvery(GET_PAYMENT_DETAILS, fetchUserPaymentDetails)
  yield takeEvery(GET_USER_OWNED_WORKSPACE, fetchUserOwnedWorkspace)
  yield takeEvery(GET_USER_OTHER_WORKSPACE, fetchUserOtherWorkspace)
  yield takeEvery(GET_BILLING_HISTORY, fetchUserBillingHistory)
  yield takeEvery(UPDATE_FREE_TRIAL, updateFreeTrial)
  yield takeEvery(ADD_NEW_USER, onAddNewUser)
  yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(DELETE_USER, onDeleteUser)
}

export default contactsSaga

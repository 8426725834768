import { WORKSPACE_LOADING } from "store/workspace/actionTypes"
import {
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
  GET_USER_PAYMENT_FAIL,
  GET_USER_PAYMENT_SUCCESS,
  GET_USER_OWNED_WORKSPACE_SUCCESS,
  GET_USER_OWNED_WORKSPACE_FAIL,
  UPDATE_FREE_TRIAL_SUCCESS,
  UPDATE_FREE_TRIAL_FAIL,
  RESET_TRIAL_STATE,
  GET_BILLING_HISTORY_SUCESS,
  GET_BILLING_HISTORY_FAIL,
  GET_USER_OTHER_WORKSPACE_SUCCESS,
  GET_USER_OTHER_WORKSPACE_FAIL,
  SHOW_LOADING,
  OTHER_WORKSPACE_LOADING,
  BILLING_LOADING,
  USER_WORKSPACE_LOADING,
  DELETE_USER_LOADING,
} from "./actionTypes"

const INIT_STATE = {
  users: [],
  userProfile: {},
  userPayment: {},
  userWorkspace: [],
  error: {},
  freeTrialData: {},
  userBillingHistory: [],
  userOtherWorkspace: [],
  isLoading: false,
  workspaceLoading: false,
  otherWorkspaceLoading: false,
  billingLoading: false,
  deleteUserLoading: false,
}

const contacts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        isLoading: false,
      }

    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    case SHOW_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case GET_USER_PAYMENT_SUCCESS:
      return {
        ...state,
        userPayment: action.payload,
      }

    case GET_USER_PAYMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_USER_OWNED_WORKSPACE_SUCCESS:
      return {
        ...state,
        userWorkspace: action.payload,
        workspaceLoading: false,
      }

    case GET_USER_OWNED_WORKSPACE_FAIL:
      return {
        ...state,
        error: action.payload,
        workspaceLoading: false,
      }
    case USER_WORKSPACE_LOADING:
      return {
        ...state,
        workspaceLoading: true,
      }
    case GET_USER_OTHER_WORKSPACE_SUCCESS:
      return {
        ...state,
        userOtherWorkspace: action.payload,
        otherWorkspaceLoading: false,
      }

    case GET_USER_OTHER_WORKSPACE_FAIL:
      return {
        ...state,
        error: action.payload,
        otherWorkspaceLoading: false,
      }
    case OTHER_WORKSPACE_LOADING:
      return {
        ...state,
        otherWorkspaceLoading: true,
      }
    case UPDATE_FREE_TRIAL_SUCCESS:
      return {
        ...state,
        freeTrialData: action.payload,
      }

    case UPDATE_FREE_TRIAL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_BILLING_HISTORY_SUCESS:
      return {
        ...state,
        userBillingHistory: action.payload.data,
        billingLoading: false,
      }

    case GET_BILLING_HISTORY_FAIL:
      return {
        ...state,
        error: action.payload,
        billingLoading: false,
      }
    case BILLING_LOADING:
      return {
        ...state,
        billingLoading: true,
      }
    case RESET_TRIAL_STATE:
      return {
        ...state,
        freeTrialData: {},
      }

    case ADD_USER_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload],
      }

    case ADD_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
      }

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map(user =>
          user.id === action.payload.id ? { user, ...action.payload } : user
        ),
      }

    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteUserLoading: false,
        users: {
          ...state.users,
          data: state.users.data.filter(user => user.id !== action.payload),
        },
      }
    case DELETE_USER_LOADING:
      return {
        ...state,
        deleteUserLoading: true,
      }
    case DELETE_USER_FAIL:
      return {
        ...state,
        deleteUserLoading: false,
        error: action.payload,
      }

    case GET_USER_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default contacts

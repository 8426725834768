import {
  GET_ROADMAP_SUCCESS,
  GET_ROADMAP_FAIL,
  ROADMAP_LOADING,
} from "./actionTypes"

const INIT_STATE = { roadmaps: [], error: {}, isLoading: false }

const roadmaps = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ROADMAP_SUCCESS:
      return {
        ...state,
        roadmaps: action.payload,
        isLoading: false,
      }
    case GET_ROADMAP_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }

    case ROADMAP_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    default:
      return state
  }
}

export default roadmaps

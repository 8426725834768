import {
  GET_USER_PROFILE,
  GET_USER_PROFILE_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  ADD_NEW_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_PAYMENT_DETAILS,
  GET_USER_PAYMENT_SUCCESS,
  GET_USER_PAYMENT_FAIL,
  GET_USER_OWNED_WORKSPACE,
  GET_USER_OWNED_WORKSPACE_SUCCESS,
  GET_USER_OWNED_WORKSPACE_FAIL,
  UPDATE_FREE_TRIAL,
  UPDATE_FREE_TRIAL_SUCCESS,
  UPDATE_FREE_TRIAL_FAIL,
  RESET_TRIAL_STATE,
  GET_BILLING_HISTORY,
  GET_BILLING_HISTORY_SUCESS,
  GET_BILLING_HISTORY_FAIL,
  GET_USER_OTHER_WORKSPACE,
  GET_USER_OTHER_WORKSPACE_SUCCESS,
  GET_USER_OTHER_WORKSPACE_FAIL,
  SHOW_LOADING,
  USER_WORKSPACE_LOADING,
  OTHER_WORKSPACE_LOADING,
  BILLING_LOADING,
  DELETE_USER_LOADING,
} from "./actionTypes"

export const showLoading = () => ({
  type: SHOW_LOADING,
})

export const getUsers = userData => ({
  type: GET_USERS,
  payload: userData,
})

export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
})

export const addNewUser = user => ({
  type: ADD_NEW_USER,
  payload: user,
})

export const addUserSuccess = user => ({
  type: ADD_USER_SUCCESS,
  payload: user,
})

export const addUserFail = error => ({
  type: ADD_USER_FAIL,
  payload: error,
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})

export const getUserProfile = userId => ({
  type: GET_USER_PROFILE,
  payload: userId,
})

export const getUserProfileSuccess = userProfile => ({
  type: GET_USER_PROFILE_SUCCESS,
  payload: userProfile,
})

export const getUserProfileFail = error => ({
  type: GET_USER_PROFILE_FAIL,
  payload: error,
})

export const getUserPaymentDetail = userId => ({
  type: GET_PAYMENT_DETAILS,
  payload: userId,
})

export const getUserPaymentDetailsSuccess = userPayment => ({
  type: GET_USER_PAYMENT_SUCCESS,
  payload: userPayment,
})

export const getUserPaymentDetailsFail = error => ({
  type: GET_USER_PAYMENT_FAIL,
  payload: error,
})

export const getUserOwnedWorkspace = data => ({
  type: GET_USER_OWNED_WORKSPACE,
  payload: data,
})

export const getUserOwnedWorkspaceSuccess = userWorkspace => ({
  type: GET_USER_OWNED_WORKSPACE_SUCCESS,
  payload: userWorkspace,
})

export const getUserOwnedWorkspaceFail = error => ({
  type: GET_USER_OWNED_WORKSPACE_FAIL,
  payload: error,
})

export const userWorkspaceLoadingFunc = () => ({
  type: USER_WORKSPACE_LOADING,
})

export const getUserOtherWorkspace = data => ({
  type: GET_USER_OTHER_WORKSPACE,
  payload: data,
})

export const getUserOtherWorkspaceSuccess = userWorkspace => ({
  type: GET_USER_OTHER_WORKSPACE_SUCCESS,
  payload: userWorkspace,
})

export const getUserOtherWorkspaceFail = error => ({
  type: GET_USER_OTHER_WORKSPACE_FAIL,
  payload: error,
})

export const otherWorkspaceLoadingFunc = () => ({
  type: OTHER_WORKSPACE_LOADING,
})

export const getUserBillingHistory = data => ({
  type: GET_BILLING_HISTORY,
  payload: data,
})
export const getUserBillingHistorySuccess = billingHistory => ({
  type: GET_BILLING_HISTORY_SUCESS,
  payload: billingHistory,
})
export const getUserBillingHistoryFail = error => ({
  type: GET_BILLING_HISTORY_FAIL,
  payload: error,
})

export const billingLoadingFunc = () => ({
  type: BILLING_LOADING,
})

export const updateUserFreeTrial = trialData => ({
  type: UPDATE_FREE_TRIAL,
  payload: trialData,
})

export const updateUserFreeTrialSuccess = trialData => ({
  type: UPDATE_FREE_TRIAL_SUCCESS,
  payload: trialData,
})

export const updateUserFreeTrialFail = error => ({
  type: UPDATE_FREE_TRIAL_FAIL,
  payload: error,
})

export const resetUserFreeTrialFail = () => ({
  type: RESET_TRIAL_STATE,
})

export const updateUser = user => ({
  type: UPDATE_USER,
  payload: user,
})

export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
})

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
})

export const deleteUserAction = user => ({
  type: DELETE_USER,
  payload: user,
})

export const deleteUserSuccess = user => ({
  type: DELETE_USER_SUCCESS,
  payload: user,
})

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error,
})

export const deleteUserLoading = () => ({
  type: DELETE_USER_LOADING,
})

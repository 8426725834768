/* USERS */
export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"
export const SHOW_LOADING = "SHOW_LOADING"
export const USER_WORKSPACE_LOADING = "USER_WORKSPACE_LOADING"
export const OTHER_WORKSPACE_LOADING = "OTHER_WORKSPACE_LOADING"
export const BILLING_LOADING = "BILLING_LOADING"

/* USERS PROFILE */
export const GET_USER_PROFILE = "GET_USER_PROFILE"
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS"
export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL"

/**
 * user payment
 */

export const GET_PAYMENT_DETAILS = "GET_PAYMENT_DETAILS"
export const GET_USER_PAYMENT_SUCCESS = "GET_USER_PAYMENT_SUCCESS"
export const GET_USER_PAYMENT_FAIL = "GET_USER_PAYMENT_FAIL"

/**
 * user owned workspace
 */

export const GET_USER_OWNED_WORKSPACE = "GET_USER_OWNED_WORKSPACE"
export const GET_USER_OWNED_WORKSPACE_SUCCESS =
  "GET_USER_OWNED_WORKSPACE_SUCCESS"
export const GET_USER_OWNED_WORKSPACE_FAIL = "GET_USER_OWNED_WORKSPACE_FAIL"

/**
 * user user other workspace
 */

export const GET_USER_OTHER_WORKSPACE = "GET_USER_OTHER_WORKSPACE"
export const GET_USER_OTHER_WORKSPACE_SUCCESS =
  "GET_USER_OTHER_WORKSPACE_SUCCESS"
export const GET_USER_OTHER_WORKSPACE_FAIL = "GET_USER_OTHER_WORKSPACE_FAIL"

/**
 * user billing history
 */

export const GET_BILLING_HISTORY = "GET_BILLING_HISTORY"
export const GET_BILLING_HISTORY_SUCESS = "GET_BILLING_HISTORY_SUCESS"
export const GET_BILLING_HISTORY_FAIL = "GET_BILLING_HISTORY_FAIL"

/**
 * free trial
 */

export const UPDATE_FREE_TRIAL = "UPDATE_FREE_TRIAL"
export const UPDATE_FREE_TRIAL_SUCCESS = "UPDATE_FREE_TRIAL_SUCCESS"
export const UPDATE_FREE_TRIAL_FAIL = "UPDATE_FREE_TRIAL_FAIL"
export const RESET_TRIAL_STATE = "RESET_TRIAL_STATE"

/**
 * add user
 */
export const ADD_NEW_USER = "ADD_NEW_USER"
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS"
export const ADD_USER_FAIL = "ADD_USER_FAIL"

/**
 * Edit user
 */
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"

/**
 * Delete user
 */
export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAIL = "DELETE_USER_FAIL"
export const DELETE_USER_LOADING = "DELETE_USER_LOADING"

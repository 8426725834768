import {
  GET_ROADMAP,
  GET_ROADMAP_SUCCESS,
  GET_ROADMAP_FAIL,
  ROADMAP_LOADING,
} from "./actionTypes"

export const getRoadmap = roadmapData => ({
  type: GET_ROADMAP,
  payload: roadmapData,
})

export const getRoadmapSuccess = roadmap => ({
  type: GET_ROADMAP_SUCCESS,
  payload: roadmap,
})

export const getRoadmapFail = error => ({
  type: GET_ROADMAP_FAIL,
  payload: error,
})

export const roadmapLoading = () => ({
  type: ROADMAP_LOADING,
})

import {
  GET_WORKSPACE_SUCCESS,
  GET_WORKSPACE_FAIL,
  WORKSPACE_LOADING,
} from "./actionTypes"

const INIT_STATE = {
  workspace: [],
  error: {},
  isLoading: false,
}

const workspaces = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_WORKSPACE_SUCCESS:
      return {
        ...state,
        workspace: action.payload,
        isLoading: false,
      }

    case GET_WORKSPACE_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    case WORKSPACE_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    default:
      return state
  }
}

export default workspaces

import axios from "axios"
import authHeader from "./jwt-token-access/auth-token-header"

const API_URL = process.env.REACT_APP_SERVER_URL

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

//========

export const request = async config => {
  if (!config.headers) {
    config.headers = {}
  }
  if (!config.headers["Content-Type"]) {
    config.headers["Content-Type"] = "application/json"
  }
  config.headers["Authorization"] = authHeader()

  const response = await axiosApi.request({ ...config })

  return {
    data: response.data,
  }
}

//========

export async function get(url) {
  const response = await request({
    url,
    method: "GET",
  })
  return response.data.data
}

export async function post(url, data) {
  const response = await request({
    url,
    method: "POST",
    data,
  })
  return response.data.data
}

export async function put(url, data) {
  const response = await request({
    url,
    method: "PUT",
    data,
  })
  return response.data
}

export async function del(url) {
  const response = await request({
    url,
    method: "DELETE",
  })
  return response.data.data
}

import { call, put, takeEvery } from "redux-saga/effects"
import { getRoadmaps } from "../../helpers/fakebackend_helper"
import { GET_ROADMAP } from "./actionTypes"
import { getRoadmapFail, getRoadmapSuccess } from "./action"

function* fetchRoadmap({ payload: roadmapData }) {
  try {
    const response = yield call(getRoadmaps, roadmapData)
    yield put(getRoadmapSuccess(response))
  } catch (error) {
    yield put(getRoadmapFail(error))
  }
}

function* roadmapSaga() {
  yield takeEvery(GET_ROADMAP, fetchRoadmap)
}

export default roadmapSaga
